// .keyword-list {
//     //
// }

.keyword-list__item {
    display: flex;
    align-items: center;
    height: 100%;
    max-height: 18px;
    background-color: #F3E69E;
    border-radius: 4px;
}