html,
body {
  font-family: $font-family-sans-serif;
  font-size: $font-size-080;
  font-weight: $font-weight-regular;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}
