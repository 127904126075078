@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot");
  src: url("../fonts/icomoon.eot") format("embedded-opentype"),
    url("../fonts/icomoon.ttf") format("truetype"),
    url("../fonts/icomoon.woff") format("woff"),
    url("../fonts/icomoon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-profile-icon:before {
  content: "\e900";
}
.icon-facebook-icon:before {
  content: "\e901";
}
.icon-twitter-icon:before {
  content: "\e902";
}
.icon-linkedin-icon:before {
  content: "\e903";
}
.icon-traditional-media-icon:before {
  content: "\e904";
}
.icon-forum-icon:before {
  content: "\e905";
}
.icon-print-media-icon:before {
  content: "\e906";
}
.icon-search-icon:before {
  content: "\e907";
}
.icon-download-icon:before {
  content: "\e908";
}
.icon-chevron-down-icon:before {
  content: "\e909";
}
.icon-arrow-down-icon:before {
  content: "\e90a";
}
.icon-dots-horizontal-icon:before {
  content: "\e90b";
}