.fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.fullscreen .image {
  background-position: 50% 50%;
  background-size: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0.5rem;
  padding: 0.5rem;
  border-radius: 2rem;
  transition: all 0.25s ease-out;
  font-size: 1.1rem;
}
