.sidebar {
  position: relative;
  height: 100%;
  min-height: 100vh;
  width: $sidebar-width;
  overflow: hidden;
  overflow-anchor: none;
  touch-action: auto;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
}
