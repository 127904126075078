.listree-menu__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
  font-size: 15px;
  cursor: pointer;
}

.listree-menu__item + ul {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #f9fafe;
}

.listree-menu__item + ul > li {
  padding-top: 9px;
  padding-bottom: 9px;

  &:hover {
    opacity: 0.8;
  }

  a {
    font-size: 14px;
  }
}

.listree-menu__trigger {
  position: relative;
  width: 17px;
  height: 17px;
  display: flex;
  align-items: center;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }

  svg {
    transition: transform ease-in-out 0.1s;
    transform: rotate(-90deg);
  }

  .expanded & {
    svg {
      transition: transform ease-in-out 0.1s;
      transform: rotate(0deg);
    }
  }
}
