.root--login {
  display: flex;
  align-items: center;
  justify-content: center;
}

.root--login__wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.root--login__content {
  flex: 1 0 auto;
  flex-direction: column;
  width: 272px;
}

.root--login__auth {
  position: relative;
  width: 100%;
  margin: 70px 0;
  min-height: 221px;
  transition: height 0.2s ease-in;
  box-sizing: border-box;
}