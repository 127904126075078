/**
 * @tokens Font family
 * @presenter FontFamily
 */

$font-family-sans-serif: 'Montserrat', sans-serif;
$font-family-serif: "Times New Roman", times, serif;
$font-family-monospace: "Lucida Console", monaco, monospace;
$font-family-default: $font-family-sans-serif;

/**
 * @tokens Font sizes
 * @presenter FontSize
 */
//// 3. Text style
$font-size-020: 12px;
$font-size-040: 13px;
$font-size-060: 14px;
$font-size-080: 16px;  // Default font size
$font-size-100: 18px;
$font-size-150: 20px;
$font-size-200: 24px;
$font-size-250: 28px;
$font-size-300: 32px;
$font-size-400: 40px;
$font-size-500: 52px;
$font-size-600: 60px;
$font-size-700: 75px;


/**
 * @tokens Font weights
 * @presenter FontWeight
 */

$font-weight-thin: 100;
$font-weight-book: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-black: 900;

/**
 * @tokens Layout components
 */

$sidebar-width: 222px;