// Stats
.stats__item--vote {
  &.positive {
    background-color: #b3e3ab; // green
  }

  &.negative {
    background-color: #ffd79a; // orange
  }

  &.neutral {
    background-color: #ba9aff; // lilac
  }
}

// Panel Links
.links-list__item {
  position: relative;
  padding-right: 10px;
  margin-right: 10px;

  &:before {
    content: "|";
    position: absolute;
    right: -2px;
    font-size: 10px;
  }

  &:last-child {
    &:before {
      content: none;
    }
  }
}
