@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
/**
 * @tokens Font family
 * @presenter FontFamily
 */
/**
 * @tokens Font sizes
 * @presenter FontSize
 */
/**
 * @tokens Font weights
 * @presenter FontWeight
 */
/**
 * @tokens Layout components
 */
@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot");
  src: url("../fonts/icomoon.eot") format("embedded-opentype"), url("../fonts/icomoon.ttf") format("truetype"), url("../fonts/icomoon.woff") format("woff"), url("../fonts/icomoon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-profile-icon:before {
  content: "";
}

.icon-facebook-icon:before {
  content: "";
}

.icon-twitter-icon:before {
  content: "";
}

.icon-linkedin-icon:before {
  content: "";
}

.icon-traditional-media-icon:before {
  content: "";
}

.icon-forum-icon:before {
  content: "";
}

.icon-print-media-icon:before {
  content: "";
}

.icon-search-icon:before {
  content: "";
}

.icon-download-icon:before {
  content: "";
}

.icon-chevron-down-icon:before {
  content: "";
}

.icon-arrow-down-icon:before {
  content: "";
}

.icon-dots-horizontal-icon:before {
  content: "";
}

/* Document */
/**
* 1. Correct the line height in all browsers.
* 2. Prevent adjustments of font size after orientation changes in iOS.
*/
html {
  line-height: 1.15;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/* Sections */
/**
  * Remove the margin in all browsers.
  */
body {
  margin: 0;
}

/**
  * Render the `main` element consistently in IE.
  */
main {
  display: block;
}

/**
  * Correct the font size and margin on `h1` elements within `section` and
  * `article` contexts in Chrome, Firefox, and Safari.
  */
h1 {
  margin: 0.67em 0;
  font-size: 2em;
}

/* Grouping content */
/**
  * 1. Add the correct box sizing in Firefox.
  * 2. Show the overflow in Edge and IE.
  */
hr {
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
  box-sizing: content-box;
  /* 1 */
}

/**
  * 1. Correct the inheritance and scaling of font size in all browsers.
  * 2. Correct the odd `em` font sizing in all browsers.
  */
pre {
  font-family: monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Text-level semantics */
/**
  * Remove the gray background on active links in IE 10.
  */
a {
  background-color: transparent;
}

/**
  * 1. Remove the bottom border in Chrome 57-
  * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
  */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
}

/**
  * Add the correct font weight in Chrome, Edge, and Safari.
  */
b,
strong {
  font-weight: bolder;
}

/**
  * 1. Correct the inheritance and scaling of font size in all browsers.
  * 2. Correct the odd `em` font sizing in all browsers.
  */
code,
kbd,
samp {
  font-family: monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
  * Add the correct font size in all browsers.
  */
small {
  font-size: 80%;
}

/**
  * Prevent `sub` and `sup` elements from affecting the line height in
  * all browsers.
  */
sub,
sup {
  position: relative;
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content */
/**
  * Remove the border on images inside links in IE 10.
  */
img {
  border-style: none;
}

/* Forms */
/**
  * 1. Change the font styles in all browsers.
  * 2. Remove the margin in Firefox and Safari.
  */
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  /* 2 */
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
}

/**
  * Show the overflow in IE.
  * 1. Show the overflow in Edge.
  */
button,
input {
  /* 1 */
  overflow: visible;
}

/**
  * Remove the inheritance of text transform in Edge, Firefox, and IE.
  * 1. Remove the inheritance of text transform in Firefox.
  */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
  * Correct the inability to style clickable types in iOS and Safari.
  */
button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

/**
  * Remove the inner border and padding in Firefox.
  */
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

/**
  * Restore the focus styles unset by the previous rule.
  */
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted transparent;
}

/**
  * Correct the padding in Firefox.
  */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
  * 1. Correct the text wrapping in Edge and IE.
  * 2. Correct the color inheritance from `fieldset` elements in IE.
  * 3. Remove the padding so developers are not caught out when they zero out
  *    `fieldset` elements in all browsers.
  */
legend {
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
  * Add the correct vertical alignment in Chrome, Firefox, and Opera.
  */
progress {
  vertical-align: baseline;
}

/**
  * Remove the default vertical scrollbar in IE 10+.
  */
textarea {
  overflow: auto;
}

/**
  * 1. Add the correct box sizing in IE 10.
  * 2. Remove the padding in IE 10.
  */
[type=checkbox],
[type=radio] {
  padding: 0;
  /* 2 */
  box-sizing: border-box;
  /* 1 */
}

/**
  * Correct the cursor style of increment and decrement buttons in Chrome.
  */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
  * 1. Correct the odd appearance in Chrome and Safari.
  * 2. Correct the outline style in Safari.
  */
[type=search] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

/**
  * Remove the inner padding in Chrome and Safari on macOS.
  */
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
  * 1. Correct the inability to style clickable types in iOS and Safari.
  * 2. Change font properties to `inherit` in Safari.
  */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/* Interactive */
/*
  * Add the correct display in Edge, IE 10+, and Firefox.
  */
details {
  display: block;
}

/*
  * Add the correct display in all browsers.
  */
summary {
  display: list-item;
}

/* Misc */
/**
  * Add the correct display in IE 10+.
  */
template {
  display: none;
}

/**
  * Add the correct display in IE 10.
  */
[hidden] {
  display: none;
}

img {
  max-width: 100%;
}

html,
body {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

.button {
  letter-spacing: 0;
  text-decoration: none;
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: 144px;
  min-height: 31px;
  border: none;
  outline: none;
  line-height: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  overflow: visible;
  vertical-align: middle;
  border-radius: 0;
  cursor: pointer;
}

.header {
  height: 61px;
}

.breadcrumb__item {
  position: relative;
  padding-left: 10px;
}
.breadcrumb__item:before {
  content: "/";
  right: -0.3rem;
  bottom: 0.2rem;
  position: absolute;
  line-height: 0.85rem;
}
.breadcrumb__item:first-child {
  padding-left: none;
}
.breadcrumb__item:last-child:before {
  content: none;
}

.keyword-list__item {
  display: flex;
  align-items: center;
  height: 100%;
  max-height: 18px;
  background-color: #F3E69E;
  border-radius: 4px;
}

.sidebar {
  position: relative;
  height: 100%;
  min-height: 100vh;
  width: 222px;
  overflow: hidden;
  overflow-anchor: none;
  touch-action: auto;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
}

.listree-menu__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
  font-size: 15px;
  cursor: pointer;
}

.listree-menu__item + ul {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #f9fafe;
}

.listree-menu__item + ul > li {
  padding-top: 9px;
  padding-bottom: 9px;
}
.listree-menu__item + ul > li:hover {
  opacity: 0.8;
}
.listree-menu__item + ul > li a {
  font-size: 14px;
}

.listree-menu__trigger {
  position: relative;
  width: 17px;
  height: 17px;
  display: flex;
  align-items: center;
}
.listree-menu__trigger:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.listree-menu__trigger svg {
  transition: transform ease-in-out 0.1s;
  transform: rotate(-90deg);
}
.expanded .listree-menu__trigger svg {
  transition: transform ease-in-out 0.1s;
  transform: rotate(0deg);
}

.filter-bar {
  min-height: 70px;
}

.search {
  cursor: pointer;
}
.search input[type=text] {
  width: 0;
  transition: width ease 0.3s;
  outline: none;
}
.search input:-moz-placeholder,
.search input::-webkit-input-placeholder {
  color: black;
}
.search:hover input[type=text],
.search input[type=text]:focus {
  width: 267px;
}

.stats__item--vote.positive {
  background-color: #b3e3ab;
}
.stats__item--vote.negative {
  background-color: #ffd79a;
}
.stats__item--vote.neutral {
  background-color: #ba9aff;
}

.links-list__item {
  position: relative;
  padding-right: 10px;
  margin-right: 10px;
}
.links-list__item:before {
  content: "|";
  position: absolute;
  right: -2px;
  font-size: 10px;
}
.links-list__item:last-child:before {
  content: none;
}

.socials-list {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding-left: 0;
}

.socials-list__item {
  display: flex;
  align-items: center;
}

.socials-list__item a {
  display: flex;
  align-items: center;
  transition: all ease 0.3s;
}
.socials-list__item a:hover {
  opacity: 0.55;
}

.pagination .active {
  font-weight: 700;
}

.fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.fullscreen .image {
  background-position: 50% 50%;
  background-size: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0.5rem;
  padding: 0.5rem;
  border-radius: 2rem;
  transition: all 0.25s ease-out;
  font-size: 1.1rem;
}

.root--login {
  display: flex;
  align-items: center;
  justify-content: center;
}

.root--login__wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.root--login__content {
  flex: 1 0 auto;
  flex-direction: column;
  width: 272px;
}

.root--login__auth {
  position: relative;
  width: 100%;
  margin: 70px 0;
  min-height: 221px;
  transition: height 0.2s ease-in;
  box-sizing: border-box;
}

body {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}

