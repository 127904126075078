
@import "01_tools/index";
@import "02_settings/index";
@import "03_normalize-scss/index";
@import "04_generic/index";
@import "05_elements/index";
@import "06_objects/index";
@import "07_components/index";
@import "08_utilities/index";
@import "09_vendor/index";
@import "10_pages/index";

body {
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
}