.button {
  letter-spacing: 0;
  text-decoration: none;
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: $button-min-width;
  min-height: $button-min-height;
  border: none;
  outline: none;
  line-height: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  overflow: visible;
  vertical-align: middle;
  border-radius: 0;
  cursor: pointer;
}
