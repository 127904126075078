.socials-list {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding-left: 0;
}

.socials-list__item {
  display: flex;
  align-items: center;
}

.socials-list__item a {
  display: flex;
  align-items: center;
  transition: all ease .3s;

  &:hover {
    opacity: .55;
  }
}
