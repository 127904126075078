.breadcrumb__item {
  position: relative;
  padding-left: 10px;

  &:before {
    content: "/";
    right: -.3rem;
    bottom: .2rem;
    position: absolute;
    line-height: .85rem;
  }

  &:first-child {
    padding-left: none;
  }

  &:last-child {
    &:before {
      content: none;
    }
  }
}
