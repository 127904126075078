.search {
  cursor: pointer;

  input[type="text"] {
    width: 0;
    transition: width ease 0.3s;
    outline: none;
  }

  input:-moz-placeholder,
  input::-webkit-input-placeholder {
    color: black;
  }

  &:hover input[type="text"],
  input[type="text"]:focus {
    width: 267px;
  }
}
